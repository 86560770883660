import { NumberSymbol } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import Peaks, {
  PeaksInstance,
  PeaksOptions,
  Point,
  SetSourceOptions,
  Segment,
} from 'peaks.js';

@Component({
  selector: 'labeling-component',
  templateUrl: './labeling-component.component.html',
  styleUrls: ['./labeling-component.component.scss'],
})
export class LabelingComponentComponent implements AfterViewInit {
  fileUrl: string = '';
  peaks?: PeaksInstance;
  video?: HTMLVideoElement | null;
  file?: File;
  videoNumber: number = 0;
  filesLength?: number;
  currentFileName: String = '';
  currentPreset: String = 'No preset loaded';
  importedData: String = 'No data loaded';
  presetImport?: any;
  frameRate: number = 24; // HardCoded
  lastUsedSegment: string = '';
  darkMode: Boolean = false;
  editable: Boolean = true;
  // controls
  hotkeys: Array<string> = ['n', 'v', 'ArrowRight', 'ArrowLeft'];
  nextKey: String = 'n';
  backKey: String = 'v';
  nextFrameKey: String = 'ArrowRight';
  lastFrameKey: String = 'ArrowLeft';
  // data save
  peaksData: Array<any> = [];

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((document.activeElement as HTMLInputElement).type === 'text') return;
    if (event.key === 'b') {
      event.preventDefault();
      var video = document.getElementById('video') as HTMLVideoElement;
      if(video.paused) {
        video.play();
        return;
      }
      video.pause();
    }
    if (event.key === this.nextKey) {
      if(!this.editable) return;
      if (this.videoNumber + 1 === this.filesLength) {
        console.log('end of videos reached');
        return;
      }
      // next video
      this.editable = false;
      this.saveData(this.videoNumber);
      this.videoNumber++;
      this.initialize();
    }
    if (event.key === this.backKey) {
      if(!this.editable) return;
      if (this.videoNumber === 0) {
        console.log('start of videos reached');
        return;
      }
      // last video
      this.editable = false;
      this.saveData(this.videoNumber);
      this.videoNumber--;
      this.initialize();  
      }
    if (event.key === this.nextFrameKey || event.key === this.lastFrameKey) {
      var video = document.getElementById('video') as HTMLVideoElement;
      if (event.key === this.nextFrameKey)
        video.currentTime += 1 / this.frameRate;
      else video.currentTime -= 1 / this.frameRate;
    } else {
      var button = document.querySelectorAll(
        `[value="${event.key}"]`
      )[0] as HTMLButtonElement;
      if (button) button.click();
    }
  }

  // on init
  ngAfterViewInit(): void {}

  // on changes
  ngOnChanges(changes: SimpleChanges) {}

  uploadVideo() {
    this.loadVideoFile();
    this.initPeaks();
    var fileInput: HTMLInputElement | null = document.getElementById(
      'video_input'
    ) as HTMLInputElement;
    if(fileInput.files) {
      for(let i = 0; i < fileInput.files.length; i++) {
        this.peaksData.push({ points: [], segments: [], videoName: fileInput.files[i].name});
      }
    }
  }

  initialize(peaksData?: Array<Object>) {
    this.loadVideoFile();
    this.initPeaks();
  }

  saveData(videoNumber?: number) {
    // saving
    let data = {
      videoName: this.currentFileName,
      points: this.peaks?.points.getPoints(),
      segments: this.peaks?.segments.getSegments(),
    };
    if(videoNumber) {
      this.peaksData?.splice(videoNumber, 1, data);
    } else {
      this.peaksData?.splice(this.videoNumber, 1, data);
    }
  }

  loadData() {
    if (this.peaksData) {
      if (this.peaksData[this.videoNumber]) {
        const pointsData = this.peaksData[this.videoNumber].points;
        const segmentData = this.peaksData[this.videoNumber].segments;
        for (let i = 0; i < pointsData.length; i++) {
          this.peaks?.points.add({
            time: pointsData[i].time as number,
            labelText: pointsData[i].labelText,
            editable: true,
          });
        }
        for (let i = 0; i < segmentData.length; i++) {
          this.peaks?.segments.add([
            {
              startTime: segmentData[i].startTime,
              labelText: segmentData[i].labelText,
              endTime: segmentData[i].endTime,
              editable: true,
            },
          ]);
        }
      }
    }
  }

  getCurrentPoints(): any[] {
    if (this.peaks) {
      return this.peaks.points.getPoints();
    }
    return [];
  }

  getCurrentSegments(): any[] {
    if (this.peaks) {
      let segments = [];
      for(let i = 0; i < this.peaks.segments.getSegments().length; i++) {
        if (this.peaks.segments.getSegments()[i].labelText === '[object Text]') {
        } else {
          segments.push(this.peaks.segments.getSegments()[i]);
        }
      }
      return segments;
    }
    return [];
  }

  changeAmplitude(event: any) {
    let amplitude = event.target.value as number;
    var peaks = this.peaks;
    const zoomView = peaks?.views.getView('zoomview');
    zoomView?.setAmplitudeScale(parseFloat(Number(amplitude).toFixed(2)));
  }

  onPeaksInit() {
    this.loadData();
    var peaks = this.peaks;
    const zoomView = peaks?.views.getView('zoomview');
    zoomView?.setWheelMode('scroll', { captureVerticalScroll: true });
    zoomView?.setZoom({ scale: 512 });
    zoomView?.setAmplitudeScale(4.0);
    zoomView?.setWaveformColor('#282b24');
    zoomView?.setPlayedWaveformColor('#639a00');
    zoomView?.showPlayheadTime(true);
    this.editable = true;
  }

  // video loading
  loadVideoFile() {
    this.video = document.getElementById('video') as HTMLVideoElement;
    var fileInput: HTMLInputElement | null = document.getElementById(
      'video_input'
    ) as HTMLInputElement;
    var files: FileList | null = fileInput.files;
    this.filesLength = files?.length;
    if (files === null) {
      console.log('err, no files');
      return;
    } else {
      this.fileUrl = window.URL.createObjectURL(files[this.videoNumber]);
      this.file = files[this.videoNumber];
      this.currentFileName = this.file.name;

    }
  }

  // // shortcut edit
  // changeNextKey(event: any) {
  //   this.nextKey = event.data;
  // }

  // changeBackKey(event: any) {
  //   this.backKey = event.data;
  // }

  // loading JSON Preset
  loadJSON() {
    var reader = new FileReader();
    let JSONInput = document.getElementById('json_input') as HTMLInputElement;
    if (JSONInput.files) {
      this.currentPreset = JSONInput.files[0].name;
      reader.readAsText(JSONInput.files[0]);
      var self = this;
      reader.onload = function (event) {
        if (event.target) {
          let str = event.target.result as string;
          let json = JSON.parse(str);
          if (json.points) {
            for (let i = 0; i < json.points.length; i++) {
              self.addPointButton(json.points[i].name, json.points[i].hotkey);
            }
          }
          if (json.segments) {
            for (let i = 0; i < json.segments.length; i++) {
              self.addSegmentButton(
                json.segments[i].name,
                json.segments[i].hotkey
              );
            }
          }
        }
      };
    }
  }

  // importing data
  importData() {
    let JSONInput = document.getElementById('data_input') as HTMLInputElement;
    if (JSONInput.files) {
      if (JSONInput.files.length === 1) {
        var reader = new FileReader();
        this.importedData = JSONInput.files[0].name;
        reader.readAsText(JSONInput.files[0]);
        var self = this;
        reader.onload = function (event) {
          if (event.target) {
            let str = event.target.result as string;
            let json = JSON.parse(str);
            if (json.points) {
              for (let i = 0; i < json.points.length; i++) {
                self.peaks?.points.add({
                  time: json.points[i].time,
                  labelText: json.points[i].name,
                  editable: true,
                });
              }
            }
            if (json.segments) {
              for (let i = 0; i < json.segments.length; i++) {
                self.peaks?.segments.add([
                  {
                    startTime: json.segments[i].startTime,
                    labelText: json.segments[i].name,
                    endTime: json.segments[i].endTime,
                    editable: true,
                    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                  },
                ]);
              }
            }
          }
        };
      }
      else if (JSONInput.files.length > 1) {
        let readerList = [];
        for (let jsonID = 0; jsonID < JSONInput.files.length; jsonID++) {
          readerList.push(new FileReader());
          let name = JSONInput.files[jsonID].name;
          for (let videoID = 0; videoID < this.peaksData.length; videoID++) {
            if (name.replace(/\.[^/.]+$/, "") === this.peaksData[videoID].videoName) {
              readerList[jsonID].readAsText(JSONInput.files[jsonID]);
              var self = this;
              readerList[jsonID].onload = function (event) {
                if (event.target) {
                  let str = event.target.result as string;
                  let json = JSON.parse(str);
                  if (json.points) {
                    for (let i = 0; i < json.points.length; i++) {
                      if(videoID === self.videoNumber) {
                        self.peaks?.points.add({
                          time: json.points[i].time,
                          labelText: json.points[i].name,
                          editable: true,
                        });
                      }
                      self.peaksData[videoID].points.push(
                        {
                          time: json.points[i].time,
                          labelText: json.points[i].name,
                          editable: true,
                        }
                      )
                    }
                  }
                  if (json.segments) {
                    for (let i = 0; i < json.segments.length; i++) {
                      if(videoID === self.videoNumber) {
                        self.peaks?.segments.add([
                          {
                            startTime: json.segments[i].startTime,
                            labelText: json.segments[i].name,
                            endTime: json.segments[i].endTime,
                            editable: true,
                            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                          },
                        ]);
                      }
                      self.peaksData[videoID].segments.push(
                        {
                          startTime: json.segments[i].startTime,
                          labelText: json.segments[i].name,
                          endTime: json.segments[i].endTime,
                          editable: true,
                          color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                        },
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  

  // adding points and segments
  addPointButton(name?: string | any, pointShortcut?: string | any) {
    if (pointShortcut) {
      if (this.hotkeys.includes(pointShortcut)) {
        alert(`Hotkey ${pointShortcut} in this preset is already in use and will be ignored`);
        return;
      }
    }
    var self = this;
    const button = document.createElement('button');
    button.className = 'button';
    if (!name && !pointShortcut) {
      name = prompt('point name');
      if (name === null) {return;}
      while (name === null || name === '') {
        alert('please enter a valid name');
        name = prompt('point name');
        if (name === null) {return;}
      }
      pointShortcut = prompt('shortcut button');
      if (pointShortcut === null) {return;}
      while (
        pointShortcut === null ||
        pointShortcut === '' ||
        pointShortcut.length > 1 ||
        self.hotkeys.includes(pointShortcut)
      ) {
        alert('please enter a valid shortcut, this one maybe in use already. It has to be a single character');
        pointShortcut = prompt('shortcut button');
        if (pointShortcut === null) {return;}
      }
    }
    button.textContent = `[${pointShortcut}]: ${name}`;
    button.value = pointShortcut;
    self.hotkeys.push(pointShortcut);
    button.addEventListener(
      'click',
      function () {
        if (self.peaks) {
          const time = self.peaks.player.getCurrentTime();
          self.peaks.points.add({
            time: time,
            labelText: `${name}`,
            editable: true,
          });
          self.saveData();
        }
      },
      false
    );
    const points = document.getElementById('points');
    points?.appendChild(button);
  }

  addSegmentButton(name?: string | any, segmentShortcut?: string | any) {
    if (segmentShortcut) {
      if (this.hotkeys.includes(segmentShortcut)) {
        alert(`Hotkey ${segmentShortcut} in this preset is already in use and will be ignored`);
        return;
      }
    }
    var self = this;
    const button = document.createElement('button');
    button.className = 'button';
    if (!name && !segmentShortcut) {
      name = prompt('segment name');
      if (name === null) {return;}
      while (name === null || name === '') {
        alert('please enter a valid name');
        name = prompt('segment name');
        if (name === null) {return;}
      }
      segmentShortcut = prompt('shortcut button');
      if (segmentShortcut === null) {return;}
      while (
        segmentShortcut === null ||
        segmentShortcut === '' ||
        segmentShortcut.length > 1 ||
        self.hotkeys.includes(segmentShortcut)
      ) {
        alert('please enter a valid shortcut, it has to be a single character');
        segmentShortcut = prompt('shortcut button');
        if (segmentShortcut === null) {return;}
      }
    }
    button.value = segmentShortcut;
    self.hotkeys.push(segmentShortcut);
    button.textContent = `[${segmentShortcut}]: ${name}`;
    button.addEventListener(
      'click',
      function () {
        if (self.peaks) {
          const time = self.peaks.player.getCurrentTime();
          const lastSegment =
            self.peaks.segments.getSegments()[
              self.peaks.segments.getSegments().length - 1
            ];
          (this.className = 'button-pressed button'),
            (self.lastUsedSegment = name as string);
          if (self.lastUsedSegment != '' && self.lastUsedSegment != name) {
            alert('please set an endpoint for the current segment first');
            return;
          }
          if (lastSegment && lastSegment.startTime === lastSegment.endTime) {
            if (time <= lastSegment.startTime) {
              alert(
                'endpoint of a segment cannot be infront of the startpoint'
              );
            } else {
              lastSegment.update({
                labelText: `${name}`,
                startTime: lastSegment.startTime,
                endTime: time,
              });
              if (this.className === 'button-pressed button')
                (this.className = 'button'), (self.lastUsedSegment = '');
              self.saveData();
            }
            return; // is this return correct?
          } else {
            const name = this.childNodes[0];
            self.peaks.segments.add([
              {
                startTime: time,
                labelText: `${name}`,
                endTime: time,
                editable: true,
                color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              },
            ]);
          }
        }
      },
      false
    );
    const segments = document.getElementById('segments');
    segments?.appendChild(button);
  }

  deletePoint(index: number, pid: number) {
    if (confirm(`Do you really want to delete the point with id ${pid}?`)) {
      let time = this.peaks?.points.getPoints()[index].time as number;
      this.peaks?.points.removeByTime(time);
      if (this.peaksData) {
        if (this.peaksData[this.videoNumber]) {
          this.peaksData[this.videoNumber].points =
            this.peaksData[this.videoNumber].points; // for angular to see changes
        }
      }
    }
  }

  deleteSegment(index: number, sid: number) {
    if (confirm(`Do you really want to delete the segment with id ${sid}?`)) {
      let time = this.peaks?.segments.getSegments()[index].startTime as number;
      this.peaks?.segments.removeByTime(time);
      if (this.peaksData) {
        if (this.peaksData[this.videoNumber]) {
          this.peaksData[this.videoNumber].segments =
            this.peaksData[this.videoNumber].segments; // for angular to see changes
        }
      }
    }
  }

  // peaks init
  initPeaks() {
    var AudioContext = window.AudioContext;
    var audioContext = new AudioContext();
    var self = this;

    const video = document.getElementById('video') as HTMLMediaElement;
    video.onloadedmetadata = (event) => {
      const emptyBuffer = audioContext.createBuffer(1, video.duration*audioContext.sampleRate, audioContext.sampleRate);
      this.file
      ?.arrayBuffer()
      .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))      
      .then((audioBuffer) => {
        const options: PeaksOptions = {
          zoomview: {
            container: document.getElementById('zoomviewContainer'),
            playheadPadding: 0,
          },
          overview: {
            container: document.getElementById('overviewContainer'),
            playheadPadding: 0,
          },
          mediaElement: document.getElementById('video') as HTMLMediaElement,
          webAudio: {
            audioBuffer: emptyBuffer,
            scale: 128,
            multiChannel: false,
          },
        };
        // const source = audioContext.createBufferSource();
        // source.buffer = audioBuffer;
        // source.connect(audioContext.destination);
        // source.start();

        Peaks.init(options, function (err: any, peaks: any) {
          if (err) {
            console.error(
              'Failed to initialize Peaks instance: ' + err.message
            );
          }
          self.peaks = peaks;
          self.onPeaksInit();
        });
      })
      .catch((e) => {
        console.log("Audio missing or audio type incorrect. It is replaced by empty audio.");
        const emptyBuffer = audioContext.createBuffer(1, video.duration*audioContext.sampleRate, audioContext.sampleRate);
        const options: PeaksOptions = {
          zoomview: {
            container: document.getElementById('zoomviewContainer'),
            playheadPadding: 0,
          },
          overview: {
            container: document.getElementById('overviewContainer'),
            playheadPadding: 0,
          },
          mediaElement: document.getElementById('video') as HTMLMediaElement,
          webAudio: {
            audioBuffer: emptyBuffer,
            scale: 128,
            multiChannel: false,
          },
        };
        // const source = audioContext.createBufferSource();
        // source.buffer = audioBuffer;
        // source.connect(audioContext.destination);
        // source.start();

        Peaks.init(options, function (err: any, peaks: any) {
          if (err) {
            console.error(
              'Failed to initialize Peaks instance: ' + err.message
            );
          }
          self.peaks = peaks;
          self.onPeaksInit();
        });
      });
    };
  }

  //exporting all segments and points as a JSON. TODO: SAVING DATA WITH VIDEO NAMES
  export() {
    this.saveData();
    const peaksData = this.peaksData;
    let allData = [];
    if (peaksData) {
      for (var j = 0; j < peaksData?.length; j++) {
        const segments = peaksData[j].segments;
        const points = peaksData[j].points;
        let exportPoints: Array<Object> = [];
        let exportSegments: Array<Object> = [];
        if (points) {
          for (var i = 0; i < points?.length; i++) {
            let point = {
              name: points[i].labelText,
              time: points[i].time,
            };
            exportPoints.push(point);
          }
        }
        if (segments) {
          for (var i = 0; i < segments?.length; i++) {
            let segment = {
              name: segments[i].labelText,
              startTime: segments[i].startTime,
              endTime: segments[i].endTime,
            };
            exportSegments.push(segment);
          }
        }
        const data = {
          videoName: peaksData[j].videoName,
          points: exportPoints ? exportPoints : [],
          segments: exportSegments ? exportSegments : [],
        };
        allData.push(data);
        this.saveText(JSON.stringify(data), `${peaksData[j].videoName}.json`);
      }
    }
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
  }

  saveText(text: any, filename: any) {
    var a = document.createElement('a');
    a.setAttribute(
      'href',
      'data:text/plain;charset=utf-u,' + encodeURIComponent(text)
    );
    a.setAttribute('download', filename);
    a.click();
  }
  
}
